<template>
    <div class="sp_confirm_dialog">
        <v-dialog
            persistent
            width="fit-content"
            transition="scale-transition"
            dismissible
            :value="dialog"
            @input="$emit('input', $event.target.value)"
        >
            <v-card>
                <v-card-title class="warning mb-2 elevation-2">
                    <slot name="title">Confirmation</slot>
                </v-card-title>
                <v-card-text>
                    <slot></slot>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="secondary"
                        @click="cancel"
                        >Cancel</v-btn
                    >
                    <v-btn
                        text
                        color="primary"
                        @click="ok"
                        >OK</v-btn
                    >
                
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name:"ConfirmDialog",
    props: {
        transition:{
            type:String,
            default:"fab-transition"
        },
    },
    data: function(){
        return{
            resolve:null,
            dialog: false
        }
    },
    methods:{
        open: function(){
            this.dialog = true;
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        ok:function(){
            this.dialog = false;
            this.resolve(true);
        },
        cancel:function(){
            this.dialog = false;
            this.resolve(false);
        }
    }
}
</script>