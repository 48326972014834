<template>
    <validation-provider :rules="rules" :name="name" v-slot="{ errors, required }" class="d-flex flex-column">
        <v-file-input :value=files @change="handleChange" v-bind="$attrs" :multiple="multiple" v-on="$listeners"
            :label="label" :color="color" :error-messages="errors" class="flex-grow-0 flex-shrink-0">
            <template #label v-if="label">
                <div :class="[errors.length == 0 ? `${color}--text` : '']">
                    {{ getLabel(required) }}
                </div>
            </template>
            <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
                <slot :name="name" v-bind="scope" v-if="!$slots[name]" />
            </template>
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        </v-file-input>
        <v-sheet v-if="showDrop" @drop.prevent="handleDrop($event)" @dragover.prevent="handleDragOver"
            @dragenter.prevent="handleDragEnter = true" @dragleave.prevent="handleDragLeave" rounded outlined
            class="flex-grow-1 drop-sheet d-flex justify-center align-center" :class="{ 'elevation-2 green-border': dragover }">
            <span>Drag & drop</span>
        </v-sheet>
    </validation-provider>
</template>


<script>
export default {
    name: "FileInput",
    props: {
        rules: {
            type: [Object, String],
            default: ""
        },
        label: {
            type: String
        },
        color: {
            type: String,
            default: "black"
        },
        name: {
            type: String,
        },
        value: {
            type: [Array, File],
            default: () => null
        },
        showDrop: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            files: null,
            dragover: false
        }
    },
    methods: {
        getLabel(required) {
            if (this.label) {
                if (required) {
                    return this.label + " *";
                }
                return this.label;
            }
            return undefined;
        },
        handleChange(ev) {
            this.files = ev;
        },
        handleDrop(e) {
            this.dragover = false;
            if (e.dataTransfer.files.length > 0) {
                if (e.dataTransfer.files.length > 1 && this.multiple) {
                    this.files = Array.from(e.dataTransfer.files);
                }else {
                    this.files = e.dataTransfer.files[0];
                }
                this.$emit('change', this.files);
            }
        },
        handleDragOver() {
            this.dragover = true
        },
        handleDragEnter() {
            this.dragover = true
        },
        handleDragLeave() {
            this.dragover = false
        },
    },
    watch: {
        value: {
            handler(newVal) {
                this.files = newVal;
            }, deep: true,
        },
        files: {
            handler(newVal) {
                this.$emit("input", newVal);
            }, deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.drop-sheet {
    min-height: 50px;
    border-style: dashed;
    border-width: thick;

    &.green-border {
        border-color: var(--v-success-base);
    }
}
</style>