<template>
    <v-container fluid class="d-flex flex-column align-stretch aspectsContainer">
        <v-row dense align="center" v-show="false">
            <v-col cols="11" class="d-flex align-self-end">
                <v-combobox
                    label="Presentation"
                    item-text="label"
                    item-value="id">
                </v-combobox>
            </v-col>
            <v-col cols="1" align="start">
                <uv-button icon small color="success" title="Confirm Table Presentation">
                    <v-icon>mdi-sticker-check-outline</v-icon>
                </uv-button>
            </v-col>
        </v-row>
        <v-row dense v-for="(column, index) of selectionColumns" :key="index" class="underlinedRow" align="center"> 
                <v-col cols="auto" class="font-weight-bold d-flex">
                    <span v-if="column.textContent.trim() !== ''">{{column.textContent}}</span>
                    <span v-else>Column {{index + 1}}</span>    
                </v-col>
                <v-col v-if="getColumnData(selectionNode.attrs.xbrlId,index) && getColumnData(selectionNode.attrs.xbrlId,index).selectedInputType === 'period'" class="columnSummary">
                    <span>{{getColumnData(selectionNode.attrs.xbrlId,index).selectedInputType === 'period' ? "xbrl" : "" }}</span>
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnPeriod || reportInfo.period }} </span> 
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnPeriodType || reportInfo.period_type }}</span>
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnDecimals || reportInfo.decimals }}</span>
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnNumericScale || reportInfo.numeric_scale }}</span>
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnMonetaryUnit || reportInfo.monetary_units }}</span>
                    <span v-if="getColumnData(selectionNode.attrs.xbrlId,index).columnUnit">, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnUnit.id }}</span>
                    <span v-if="getColumnData(selectionNode.attrs.xbrlId,index).columnComplexUnit">, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnComplexUnit }}</span>
                    <span>, {{ getColumnData(selectionNode.attrs.xbrlId,index).columnNumericTransform || reportInfo.numeric_transform }}</span>
                    <!-- <span v-if="getColumnData(selectionNode.attrs.xbrlId,index).dimension?.dimensionTagName">, {{ getColumnData(selectionNode.attrs.xbrlId,index).dimension?.dimensionTagName }}</span>
                    <span v-if="getColumnData(selectionNode.attrs.xbrlId,index).dimension?.dimensionTagValue">, {{ getColumnData(selectionNode.attrs.xbrlId,index).dimension?.dimensionTagValue }}</span> -->
                </v-col> 
                <v-col cols="auto" class="ml-auto">
                    <uv-button small :disabled="isReadOnly" @click="() => openDialog(index)" color="success" icon title="Edit Column Aspects">
                        <v-icon>mdi-pencil</v-icon>
                    </uv-button>
                </v-col> 
                
        </v-row>
        <v-dialog
            :value="dialog"
            max-width="40%"
            scrollable
            persistent>
            <v-card>
                <v-card-title>XBRL Attributes</v-card-title>
                <v-card-text class="px-3">
                    <v-container fluid class="flex-column align-stretch">
                        <v-row dense>
                            <v-col :cols="columnData.selectedInputType != 'static' ? 6 : 12">
                                <uv-select 
                                    label="Column Type" 
                                    :items="selectorItems.typeOfInput" 
                                    v-model="columnData.selectedInputType">
                                </uv-select>
                            </v-col>
                            <v-col cols="6" v-if="columnData.selectedInputType === 'period'">
                                <uv-select
                                    v-model="columnData.columnPeriod"
                                    label="Period"
                                    item-text="name"
                                    :items="selectorItems.periodItems"
                                    :placeholder="reportInfo.period.toLowerCase()"
                                    persistent-placeholder
                                    clearable>

                                </uv-select>
                            </v-col>
                        </v-row>
                        <template v-if="columnData.selectedInputType === 'period'">
                            <v-row dense>
                                <v-col>
                                    <v-combobox
                                        v-model="columnData.columnPeriodType"
                                        label="Period Type"
                                        :items="selectorItems.periodTypeItems"
                                        :placeholder="reportInfo.period_type.toLowerCase()"
                                        persistent-placeholder
                                        clearable>
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="columnData.columnDecimals"
                                        label="Decimals"
                                        type="number"
                                        :placeholder="reportInfo.decimals.toString()"
                                        persistent-placeholder
                                        clearable>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <v-text-field
                                        v-model="columnData.columnNumericScale"
                                        label="Numeric Scale"
                                        type="number"
                                        :placeholder="reportInfo.numeric_scale.toString()"
                                        persistent-placeholder
                                        clearable>
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-combobox
                                        v-model="columnData.columnNumericTransform"
                                        label="Numeric Transform"
                                        :items="selectorItems.numericTransformItems"
                                        :placeholder="reportInfo.numeric_transform.toLowerCase()"
                                        persistent-placeholder
                                        clearable>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col v-if="!usedUnit || usedUnit == 1">
                                    <uv-autocomplete
                                        v-model="columnData.columnMonetaryUnit"
                                        label="Monetary Unit"
                                        :items="selectorItems.monetaryUnits"
                                        item-value="id"
                                        item-text="name"
                                        :placeholder="reportInfo.monetary_units"
                                        persistent-placeholder
                                        clearable
                                        :filter="findUnit"
                                        :return-object="true"
                                        @change="(val) => onUnitChange(val, 'monetary')"
                                        :disabled="usedUnit && usedUnit != 1">
                                        
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.id}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                    <v-icon>mdi-information-variant</v-icon>
                                                </uv-button>
                                            </v-list-item-action>
                                        </template>
                                        <template #selection="{item}">
                                            {{item.id}}
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col v-if="!usedUnit || usedUnit == 2">
                                    <uv-autocomplete
                                        v-model="columnData.columnUnit"
                                        label="Unit"
                                        :items="unitsItems"
                                        item-value="id"
                                        item-text="name"
                                        eager
                                        clearable
                                        :filter="findUnit"
                                        :return-object="true"
                                        @change="(val) => onUnitChange(val, 'unit')"
                                        :disabled="usedUnit && usedUnit != 2">
                                        
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.id}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                    <v-icon>mdi-information-variant</v-icon>
                                                </uv-button>
                                            </v-list-item-action>
                                        </template>
                                        <template #selection="{item}">
                                            {{item.id}}
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col v-if="!usedUnit || usedUnit == 3">
                                    <uv-autocomplete
                                        v-model="columnData.columnComplexUnit"
                                        label="Complex Unit"
                                        :items="reportInfo.complex_units"
                                        item-value="name"
                                        item-text="name"
                                        eager
                                        clearable
                                        :filter="findUnit"
                                        @change="(val) => onUnitChange(val, 'complex')"
                                        :disabled="usedUnit && usedUnit != 3">
                                        
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.numerator}} per {{item.denominator}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                            </v-row>
                            <v-card outlined tag="fieldset" elevation=1>
                                <legend>
                                    <v-layout>
                                        <label class="pa-2 v-label font-weight-medium info--text">Dimensions</label>
                                        <v-spacer></v-spacer>
                                        <uv-button icon color="success" title="Add dimension" @click="addDimension">
                                            <v-icon>mdi-plus</v-icon>
                                        </uv-button>
                                    </v-layout>
                                </legend>
                                
                                <template v-for="(dimension, idx) of columnData.dimensions">
                                    <v-divider v-if="idx > 0" :key="`div-${idx}`"></v-divider>
                                    <v-row class="px-1" dense  :key="`dim-${idx}`">
                                        <v-col cols="12">
                                            <v-autocomplete
                                                label="Dimension Name"
                                                v-model="dimension.dimensionTagName"
                                                @change="dimension.dimensionTagValue = ''"
                                                :items="dimensionNameItems"
                                                item-text="text"
                                                clearable>
                                                <template #append-outer>
                                                    <uv-button icon color="error" title="Remove dimension" @click="removeDimension(idx)">
                                                        <v-icon>mdi-minus</v-icon>
                                                    </uv-button>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col v-if="dimension.dimensionTagName != '' && dimension.dimensionTagName != null && dimension.dimensionTagName != 'total'">
                                            <v-autocomplete
                                            label="Dimension Value"
                                            v-model="dimension.dimensionTagValue"
                                            :items="dimensionsDict[dimension.dimensionTagName]"
                                            clearable>
                                                <template #append-outer>
                                                    <span style="width:36px"></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-card>
                        </template>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="grey darken-1"
                        text
                        @click="closeDialog"
                    >
                        Discard
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        :loading="dialogIsLoading"
                        @click="onSave(); closeDialog();"
                    >
                        Apply
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { dimensionsMixin } from "@/plugins/mixins"

export default{
    name: "ColumnAspects",
    mixins: [dimensionsMixin],
    props:{
        isReadOnly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectorItems: {
                periodItems: [],
                periodTypeItems: ['instant','instant-start','duration'],
                numericTransformItems: ['num-dot-decimal','num-comma-decimal','num-unit-decimal'],
                monetaryUnits: this.$store.state.xbrlData.monetaryUnits,
                typeOfInput: [{text: "static", value: "static"}, {text: "xbrl", value: "period"}],
            },
            usedUnit: null,
            columnData: {
                columnIndex: "",
                columnPeriodType: "",
                columnDecimals: null,
                columnNumericScale: null,
                columnMonetaryUnit: null,
                columnUnit: null,
                columnComplexUnit: null,
                columnNumericTransform: "",
                columnPeriod: "",
                selectedInputType: "",
                dimensions: [] 
            },
            dialog: false,
            dialogIsLoading: false
        }
    },
    created() {
        this.selectorItems.periodItems = this.reportInfo.periods;
    },
    computed: {
        ...mapState("xbrlData", [
            "reportInfo",
            "editorSelection",
        ]),
        ...mapGetters("xbrlData", {
            getSelectedColumn: "getColumnAspectByTagIdAndIndex",
            getTagById: "getTagById",
            getColumnData: "getColumnDataByTableXbrlIdAndIndex",
        }),
        selectionNode: function() {
            return this.editorSelection?.node;
        },
        unitsItems: function(){
            let units = [];
            const storeUnits = this.$store.state.xbrlData.units;
            for (const header of Object.keys(storeUnits)){
                units.push({header: header.replace(header[0], header[0].toUpperCase())})
                units = units.concat(storeUnits[header]);
                units.push({divider: true});
            }

            return units;
        },
        selectionColumns: function() {
            let columns = [];
            let rows = this.selectionNode?.content?.content;
            if (rows) {
                let cols = rows[0].content.content;
                for (let col of cols) {
                    columns.push(col);
                }
            }
            return columns;
        }
    },
    methods: {
        ...mapMutations(["setGlobalMessage"]),
        openDialog(index) {
            let existingColumn = this.getSelectedColumn(this.selectionNode.attrs.xbrlId, index);
            //edit
            if (existingColumn) {
                this.columnData = JSON.parse(JSON.stringify(existingColumn));
                this.columnData.selectedInputType = "period";
                if(existingColumn.dimension) {
                    //for backwards compatibility
                    this.$set(this.columnData, 'dimensions', [existingColumn.dimension])
                    delete this.columnData.dimension
                }else if (!this.columnData.dimensions){
                    this.$set(this.columnData, 'dimensions', [])
                }              
            //add
            }else{
                this.resetDialogFields();
                this.columnData.columnIndex = index;
                this.columnData.selectedInputType = "period";
            }
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.resetDialogFields();
        },
        resetDialogFields() {
            let initialColumnData = {
                columnIndex: "",
                columnPeriodType: "",
                columnDecimals: null,
                columnNumericScale: null,
                columnMonetaryUnit: null,
                columnUnit: null,
                columnComplexUnit: null,
                columnNumericTransform: "",
                columnPeriod: "",
                selectedInputType: "",
                dimensions: []

            };
            this.columnData = initialColumnData;
        },
        onSave() {
            // if(this.columnData.dimension.dimensionTagName != null && this.columnData.dimension.dimensionTagName != '') {
            //     this.$emit('applyColumnAspects', this.columnData);
            // } else {
            //     let tempColumn = Object.assign({},this.columnData);
            //     delete tempColumn.dimension;
            //     this.$emit('applyColumnAspects', tempColumn);

            // }
            this.$emit('applyColumnAspects', this.columnData);
        },
        addDimension(){
            this.columnData.dimensions.push({
                dimensionTagName: "",
                dimensionTagValue: "",
            })
        },
        removeDimension(idx){
            this.columnData.dimensions.splice(idx, 1)
        },     
        findUnit(item, queryText) {
            const searchText = queryText.toLowerCase();
            return item.name?.toLowerCase().indexOf(searchText) > -1 || item.id?.toLowerCase().indexOf(searchText) > -1;
        },
        onUnitChange(val, type){
            this.usedUnit = null
            if (!val){
                return
            }
            switch (type){
                case 'monetary':
                    this.usedUnit = val ? 1 : null
                    break
                case 'unit': {
                    if (val.ns != "http://www.xbrl.org/2003/instance"){
                        let ns = this.reportInfo.namespaces.filter((n)=> n.url == val.ns)
                        if (!ns?.length){
                            this.setGlobalMessage({
                                    message: `Namespace ${val.ns} is not defined in report info`,
                                    severity: "warning"
                                })
                        }                        
                    }
                    this.usedUnit = val ? 2 : null
                    break
                }
                case 'complex': {
                    this.usedUnit = val ? 3 : null
                    break
                }
            }
        }
    }
}

</script>

<style lang="scss" scoped>
    .aspectsContainer {
        overflow-y: auto;
        padding-top: 0;
        padding-bottom: 2;
    }
    .underlinedRow {
        border-bottom: 1px solid black;
    }
    .columnSummary {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    :deep() {
        .v-subheader{
            font-size: 0.8rem;
        }
    }
</style>